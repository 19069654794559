@delete-top-margin: -46px;

#delete-button {
  display: table-cell;
  position: relative;
  top: @delete-top-margin;
  padding-left: calc(100%);

}

#save-button {
  position: relative;
  display: inline-flex;
}

#back-button {
  position: relative;
  margin-right: 15px;
  display: inline-flex;
}
