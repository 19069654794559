body {
    /*background-image: radial-gradient( darkseagreen, lightseagreen, darkolivegreen );*/
    /*background-image: radial-gradient( darkseagreen, lightseagreen, darkolivegreen );*/
    /*background-image: radial-gradient( rgba(255,255,255,0) 0, rgba(255,255,255,1) 100% );*/
    /*background-color: darkcyan;*/
    /*background-color: white;*/
    background-image: linear-gradient(#027cc5, #004d9b);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1.0;
}

.brand {
    width: max-content;
    /*height: 100px;*/
    text-align: left;
    /*padding: 25px;*/
    /*background: #FFF;*/
    /*margin: auto;*/
}

.brand img {
    max-height: 70px;
    mix-blend-mode: hard-light;
}

#content {
    /*min-height: calc(100vh - 165px);*/
    /*padding-bottom: 70px;*/
    margin-top: 80px;
    min-height: calc(100vh - 145px);
    text-align: center;
}

ul.dropdown {
    background-color: black;
}

#content td, #content p {
    text-align: left;
}

#content h1 {
    display: inline-flex;
    margin-top: 30px;
    margin-bottom: 15px;
    text-shadow: 3px 3px black;
}

.site-footer {
    width: 100vw;
    position: relative;
    bottom: 0px;
    height: 60px;
    text-align: center;
    /*background: url('/images/silver-repeat-x.png') repeat-x;*/
    background-color: rgba(33, 122, 255, 0.5);
    padding: 5px 25px;
    margin-top: 5px;
}

.site-footer p {
    vertical-align: middle;
    margin: auto;
    padding: 13px;
}

.site-navbar {
    align-self: center;
}

#toTop {
    width: 100px;
    z-index: 10;
    border: 1px solid #333;
    background: #121212;
    text-align: center;
    padding: 5px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
    display: none;
    color: #fff;
    text-transform: lowercase;
    font-size: 0.9em;
}


.navbar-expand {
    float: right;
}

.nav-item a:link.nav-link,
.nav-item a:visited.nav-link
{
    color: white;
    font-weight: normal;
}

.nav-tabs {
    font-size: larger;
    font-weight: bold;
}

.nav-tabs {
    text-decoration: none;
    border-bottom: none;
}

.nav-tabs:hover {
    background-color: deepskyblue;
}

.nav-tabs a:link, .nav-tabs a:visited, .site-footer a:link, .site-footer a:visited {
    color: whitesmoke;
    font-weight: normal;
}

.nav-tabs a:hover.nav-link,
.nav-tabs a:focus.nav-link,
.site-footer a:hover,
.site-footer a:focus
{
    color: black;
    font-weight: bold;
    text-decoration: none;
}

.nav-item a:hover.nav-link,
.nav-item a:focus.nav-link
{
    color: yellow;
    font-weight: bold;
}

.nav-item:hover ,
.nav-item:focus {
    background-color: royalblue;
}




.site-footer .flex-column {
    display: inline-flex;
    clear: none;
    min-width: calc( 100vw / 4);
    max-width: calc( 100vw / 3);
}

header, header .site-header {
    /*background-color: skyblue;*/
    width: 100vw;
    position: fixed;
    z-index: 700;
    top: 0px;
    height: 80px;
    /*background-color: rgba(33, 199, 255, 0.7);*/
    background-color: rgba(11,88,111,.69);
    /*background-color: rgba(135,206,235,.42);*/
    padding: 5px 25px;
    margin-bottom: 10px;
    /*background-color: rgba(0,191,255,.69);*/
}

.site-header .flex-column {
    clear: none;
    display: inline-flex;
}

/*
.navbar a:link, .navbar a:visited {
    color: lightgoldenrodyellow;
}

.navbar a:hover, .navbar a:focus {
    color: palegreen;
}
*/

#canvas {
    content: "";
    /*background-image: url('/images/sunny-blue-sky-8bpp.png');*/
    /*background-image: url('/images/sunny-blue-sky.jpg');*/
    background-size: cover;
    background-blend-mode: screen;
    height: 100%;
    /*overflow-x: unset;*/
    overflow-y: auto;
    /*opacity: 0.6;*/
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

#last-view, #error-page
{
    margin: 15px auto;
    padding: 5px;
    clear: both;
    display: inline-block;
}

#error-page {
    text-align: center;
}

#error-page img {
    margin: 15px auto;
    padding: 5px;
    min-height: calc( 100vh / 3);
    max-height: calc( 100vh / 2.5);
    /*min-width: calc( 100vw / 4);*/
    /*max-width: calc( 100vh / 3);*/
    width: auto;
}

.status_code {
    margin: 5px auto;
    color: darkgoldenrod;
    font-weight: bold;
    font-size: x-large;
    text-shadow: 1px 1px black;
}

.status_text {
    margin: 15px auto;
    color: whitesmoke;
    font-weight: normal;
    font-size: x-large;
    text-shadow: 1px 1px black;
}

/*
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
*/

.payload-headers, .payload-payload,
.bounce-headers, .bounce-body {
    min-width: min-content;
    width: min-content;
    max-width: 400px;
    word-wrap: break-word;
}

.bounce-gps_id, .bounce-target_id, .bounce-status_code, .bounce-sent,
.payload-gps_id, .payload-received, .payload-latency {
    min-width: min-content;
    max-width: max-content;
    width: max-content;
    word-break: normal;
    white-space: nowrap;
}

.payload-latency {
    color: black;
}


#home-page {
    /*background-image: url('/images/devops-cycle.png');*/
    /*background-repeat: no-repeat;*/
    /*background-position-x: center;*/
    /*background-position-y: center;*/
    /*background-size: contain;*/
    /*min-height: calc(100vw/2 - 100px);*/
    margin: 10px;
    padding: 5px;
}

#home-page img {
    max-width: calc( 100vw / 10 * 9);
    max-height: calc( 100vh / 10 * 8);
    background-color: rgba( 244, 244, 244, 0.7);
    margin: 10px;
    padding: 5px;
}
#home-intro, #home-summary {
    padding-right: 10%;
    padding-left: 10%;
}

#home-page p, #settings-admin p {
    color: darkseagreen;
    font-size: x-large;
    font-weight: bold;
    text-shadow: -2px 1px 5px black;
}

#rti-diagram {
    align-content: center;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    margin: 5px auto;
}

#endpoint-headers, #endpoint-payload {
    display: inline-block;
    clear: both;
    margin-left: auto;
    margin-top: 0px;
    vertical-align: top;
    color: black;
    text-shadow: 1px 1px white;
    font-size: 125%;
}

#traffic-dashboard h1, h2, h3, h4 {
    margin: 10px auto;
    /*padding: 5px;*/
}

#traffic-page-title {
    overflow: visible;
    white-space: nowrap;
    display: inline-block;
    width: 0px;
}

#traffic-page-heading {
    padding-top: .5em;
}

#traffic-dashboard h1 {
    font-size: 1.75em;
    color: ghostwhite;
    font-weight: bold;
    font-family: 'Roboto';
    /*text-shadow: black -3px 3px 9px0*/
    text-shadow:
            -1px -1px 3px #000,
            1px -1px 3px #000,
            -1px 1px 3px #000,
            1px 1px 3px #000;
    letter-spacing: 0.05em;
}

#traffic-dashboard h2 {
    font-size: 1.25em;
    /*color: black;*/
    /*text-shadow: 1px 1px whitesmoke;*/
    font-weight: 500;
    font-family: 'Montserrat';
    color: aliceblue;
    text-shadow: black -3px 3px 7px;
    letter-spacing: 2px;
}

#traffic-dashboard h3 {
    font-size: 1.25em;
    margin-top: 15px;
    padding: 5px;
    color: black;
    font-weight: bold;
}

#traffic-dashboard h4 {
    font-size: 1.125em;
    color: gold;
    text-shadow: black -2px 2px 5px;
}

/*#traffic-page-title h1 {*/
    /*color: deepskyblue;*/
    /*font-weight: bold;*/
    /*font-family: 'Roboto';*/
    /*text-shadow: black -3px 3px 9px;*/
    /*letter-spacing: 1.5px;*/
/*}*/

#traffic-page-heading {
    height: .5em;
}

#realtime-clock,
#incoming-last-second,
#incoming-last-minute,
#outgoing-last-second,
#outgoing-last-minute {
    font-family: 'LCDMono';
    font-weight: bold;
    font-size: 1.35em;
    color: palegreen;
    text-shadow: black -2px 2px 5px;
    /*letter-spacing: 1px;*/
}

/*#incoming-column-name h2,*/
/*#outgoing-column-name h2 {*/
    /*font-family: 'Montserrat-Medium';*/
    /*color: aliceblue;*/
    /*text-shadow: black -3px 3px 7px;*/
    /*letter-spacing: 1px;*/
/*}*/


#outgoing-traffic .col,
#incoming-traffic .col {
    white-space: nowrap;
    color: black;
}

#outgoing-traffic .table-primary .col ,
#incoming-traffic .table-primary .col {
    padding: 5px 0px;
    color: ghostwhite;
    font-weight: bold;
}

.row-stripped:nth-child(even){
    background-color: #dcdcdc;
}
.row-stripped:nth-child(odd){
    background-color: #aaaaaa;
}

.row-stripped:hover,
.row-highlight:hover {
    background-color: aqua;
}


#delete-button {
    top: -46px;
}


#home-page,
#traffic-incoming,
#traffic-outgoing,
#traffic-dashboard,
#targets,
#sources,
#filters,
#groups,
#users,
#headers {
    max-width: 86%;
    margin-left: auto;
    margin-right: auto;
}

#targets .form-control,
#users .form-control,
#filters .form-control,
#headers .form-control,
#groups .form-control,
#sources .form-control {
    display: unset;
    width: 600px;
}

#targets label,
#groups label,
#sources label,
#filters label,
#users label,
#headers label
{
    font-family: 'Roboto';
    font-weight: 500;
    width: 200px;
    color: gold;
    text-shadow: -2px 2px 5px black;
    text-align: right;
    margin-right: auto;
    padding: 15px;
    font-size: 1.25em;
}

label.required:before {
    content: "* ";
}

#api-phpdoc {
    margin: 10px auto;
    width: 86%;
    height: -webkit-fill-available;
}

#swagger-ui {
    /*min-height: min-content;*/
    margin-top: 80px;
    min-height: calc(100vh - 145px);
}
#swagger-ui.api-platform .opblock-tag {
    background-color: aqua;
    margin-bottom: 15px;
}
.swagger-ui .info {
    margin: 15px auto;
    background-color: ghostwhite;
    padding: 5px 15px;
}
.opblock-summary {
    background-color: whitesmoke;
}

.opblock-section {
    background-color: navajowhite;
}

.opblock-description-wrapper {
    background-color: antiquewhite;
}

.responses-inner {
    background-color: lightcyan;
}

#login-auth {
    display: inline-block;
}

.login_form input
{
    border: none;
    padding: 10px 15px 10px 15px;
    margin: 10px auto;
    font-size: 15px;
}

.login_form
{
    display: flex;
    flex-flow: column;
    width: 310px;
    margin: 25px auto;
    min-height: min-content;
    /*height: 275px;*/
}


.login_form .submit_btn
{
    height: 44px;
    background: #00cfbb;
    border: none;
    width: 100%;
    position: relative;
    color: #FFF;
    margin: 25px auto;
}
.login_form .submit_btn:hover
{
    background: #17cbcf;
    cursor: pointer;
}

/* Clouds */
div.clouds_container
{
    bottom: 0;
    left: 0;
    padding-top: 50px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(#027cc5, #004d9b);
}
